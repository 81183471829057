<template>
	<el-dialog v-model="dialogVisible" title="编辑" width="50%" draggable @close="close">
		<el-form :model="form" label-width="120px">
		    <el-form-item label="名称">
		      <el-input v-model="form.name" />
		    </el-form-item>
		    <el-form-item label="排序">
		      <el-input v-model="form.sort" />
		    </el-form-item>
		</el-form>
		<template #footer>
			<div style="text-align: center;">
				<span class="dialog-footer">
					<el-button type="primary" size="small" @click="submit">保存</el-button>
					<el-button size="small" @click="close">关闭</el-button>
				</span>
			</div>
		</template>
	</el-dialog>
</template>

<script setup>
import { reactive, ref ,defineEmits ,inject,defineProps,onMounted} from 'vue';
import {success,error} from '@/utils/tips.js'
const dialogVisible = ref(true);
const $post=inject("$post");
const $get=inject("$get");
const emit=defineEmits(['close'])
const props=defineProps(['uuid'])
var form=ref({
	"uuid":props.uuid,
	"name":"",
	"sort":""
})
onMounted(()=>{
	if(form.value.uuid!="") find();
})
const find=async ()=>{
	const res=await $get("/api/Classify/Find",{"uuid":form.value.uuid})
	form.value=res.result;
}
const submit= async()=>{
	const res=await $post("/api/Classify/Add",form.value)
	if(res.errCode=="0"){
		error(res.msg);return;
	} 
	success(res.msg);
	close();
}
const close=()=>{
	emit('close')
}
</script>
<style scoped>
.el-button--text {
	margin-right: 15px;
}
.el-select {
	width: 300px;
}
.el-input {
	width: 300px;
}
.dialog-footer button:first-child {
	margin-right: 10px;
}
</style>
