<template>
	<div class="demo-pagination-block">
		<el-pagination
			v-model:current-page="now_search_data.page"
			v-model:page-size="now_search_data.limit"
			:page-sizes="[10, 50, 100, 400]"
			:small="small"
			layout="total, sizes, prev, pager, next, jumper"
			:total="now_search_data.total"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
		/>
	</div>
</template>

<script setup>
import {ref,toRefs } from 'vue';
const props = defineProps({
	search_data: {
		type: Object
	}
});
const {search_data} =toRefs(props)
const now_search_data=ref(search_data);
let emits = defineEmits("page_search")
const handleSizeChange = number => {
	now_search_data.page = number;
	emits('page_search',now_search_data);
};
const handleCurrentChange = number => {
	now_search_data.limit = number;
	emits('page_search',now_search_data);
};
</script>

<style></style>
